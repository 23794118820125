import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button } from "gatsby-material-ui-components";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import formatTitle from "../../components/common/formatTitle";
import Carousel from "../../components/common/Carousel";
import Grid from "@material-ui/core/Grid";
import Offre from "./offre";
import Laptop from "./laptop.svg";
import Server from "./server.svg";
import Briefcase from "./briefcase.svg";
import Ds from "./ds.svg";
import about_illustration from "./about_illustration.png";
import Boldness from "./Boldness.svg";
import Creativity from "./Creativity.svg";
import Impact from "./Impact.svg";
import Rigor from "./Rigor.svg";
import Slideshow1 from "./slideshow_1.jpg";
import Slideshow2 from "./slideshow_2.jpg";
import Bg1 from "./background_1.png";
import Layout from "../../components/layout/layout";
import VideoPlaceholder from "./video_placeholder.png";
import Video from "../../components/common/video";
import TitreTextCta from "../../components/common/TitreTexteCta";
import Newsletter from "../../components/newsletter/newsletter";
import LeonardTeam from "./leonard_team.jpg";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

const useStyles = makeStyles((theme) => ({
  blueBloc: {
    backgroundColor: theme.palette.primary.dark,
    backgroundImage: `url(${Bg1})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 0",
    backgroundSize: "auto 100%",
    textAlign: "left",
    paddingRight: "30%",
    color: "#FFF",
    padding: `${theme.spacing(26)}px 40% ${theme.spacing(26)}px ${theme.spacing(
      8
    )}px`,
  },
  blueBlocMedium: {
    padding: `${theme.spacing(6)}px 40% ${theme.spacing(6)}px ${theme.spacing(
      8
    )}px`,
  },
  em: {
    color: theme.palette.primary.light,
  },
  blueBlocTitle: {
    textAlign: "center",
    color: "#FFF",
  },
  blueBlocText: {
    color: "#FFF",
    marginTop: theme.spacing(2),
    width: "55%",
  },
  button: {
    color: theme.palette.primary.dark,
    marginTop: "2em",
    width: 200,
    padding: theme.spacing(2),
  },
  row1: {
    padding: theme.layout.paddingRegular,
  },
  paddingRegularMobile: {
    padding: theme.layout.paddingRegularMobile,
  },
  row2: {
    padding: theme.layout.paddingRegular,
  },
  withBg: {
    backgroundColor: theme.palette.background.medium,
  },
  lastRow: {
    padding: theme.layout.paddingLastRow,
  },
  lastRowMobile: {
    padding: theme.layout.paddingLastRowMobile,
  },
  centered: {
    textAlign: "center",
  },
  titleMobile: {
    fontSize: "2em",
  },
  cardB: {
    backgroundColor: theme.palette.primary.medium,
    boxShadow: "0px 60px 50px rgba(67,67,68, 0.109804);",
    padding: theme.spacing(2),
    borderRadius: "5px",
  },
  cardC: {
    backgroundColor: "#FFF",
    padding: theme.spacing(2),
    borderRadius: "5px",
  },
  cardCShadow: {
    boxShadow: "0px 60px 50px rgba(67,67,68, 0.109804);",
  },
  mB: {
    marginBottom: theme.spacing(6),
  },
  mBMobile: {
    marginBottom: theme.spacing(3),
  },
  mT: {
    marginTop: theme.spacing(6),
  },
  cardTitle: {
    fontSize: "1.3em",
    fontWeight: 1000,
    margin: `${theme.spacing(1)}px 0`,
  },
  leonard: {
    backgroundColor: theme.palette.background.medium,
    borderRadius: "5px",
  },
  leoLeftB: {
    minHeight: "75px",
    borderLeft: `solid 10px ${theme.palette.primary.light}`,
  },
  leoContent: {
    padding: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
  },
  values: {
    padding: `${theme.spacing(4)}px ${theme.spacing(12)}px`,
  },
  enLight: {
    color: theme.palette.primary.light,
  },
  leonardTitle: {
    fontWeight: 1000,
    color: theme.palette.primary.dark,
  },
  linkedin: {
    color: theme.palette.primary.dark,
  },
  mediumPadded: {
    paddingTop: 6,
  },
  mT2: {
    marginTop: theme.spacing(2),
  },
  noMargin: {
    margin: 0,
  },
  imgRnd: {
    borderRadius: "12px",
  },
}));

const JobsEn = ({ data, pageContext: { slug, langKey } }) => {
  const classes = useStyles();
  const [cardIdx, setCardIdx] = React.useState(0);
  const handleMouseEnter = (idx) => setCardIdx(idx);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const contents = data.allCareersJson.nodes[0].careers[langKey][0];
  return (
    <Layout slug={slug} langKey={langKey} siteMetadata={data.site.siteMetadata}>
      <Grid item>
        <Grid container direction="column" alignItems="stretch">
          {matches && (
            <Grid
              item
              container
              className={clsx(classes.blueBloc, {
                [classes.blueBlocMedium]: matchesMd,
              })}
              xs={12}
            >
              <TitreTextCta
                title={
                  <>
                    We are <span className={classes.em}>Pioneering</span> Smart
                    Road Technology,
                    <br />
                    through actionable intelligence
                  </>
                }
                text={contents.pioneering.content}
                buttonLabel={contents.pioneering.btnLabel}
                lightTheme
                btnColor="secondary"
                lg={8}
                ctaUrl={`/${langKey}/contact?object=jobs`}
              />
            </Grid>
          )}
          <Grid item className={classes.row1}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography
                  variant="h1"
                  className={clsx(classes.centered, {
                    [classes.mB]: matches,
                    [classes.mBMobile]: !matches,
                    [classes.titleMobile]: !matches,
                  })}
                >
                  {formatTitle("Job !!openings")}
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction={matches ? "row" : "column"}
                justify="center"
                spacing={matches ? 6 : 0}
              >
                {/* {contents.jobs.map((j, idx) => (
                  <Offre
                    key={idx}
                    title={j.title}
                    description={j.description}
                    jobType={j.type}
                    localisation={j.place}
                    matches={matches}
                    langKey={langKey}
                  />
                ))} */}
                <Typography className={clsx(classes.centered)}>
                  Are you passionate about our field of activity and would like
                  to join our team? <br />
                  Cyclope.ai is constantly on the lookout for its future stars
                  (Data Scientists, Sales, Devs, PO), and we invite you to send
                  us your spontaneous application via our online form!
                </Typography>
                <Button
                  to={`/${langKey}/contact?object=jobs`}
                  color={"primary"}
                  size={"large"}
                  variant={"contained"}
                >
                  {contents.pioneering.btnLabel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Newsletter langKey={langKey} className={classes.row1} />
          <Grid
            item
            className={clsx({
              [classes.row2]: matches,
              [classes.paddingRegularMobile]: !matches,
              [classes.withBg]: true,
            })}
          >
            <Grid
              container
              direction={matches ? "row" : "column-reverse"}
              justify="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item container direction="row" spacing={3} lg={6} xs={12}>
                <Grid item lg={6} xs={12}>
                  <div className={clsx(classes.cardB, classes.mB)}>
                    <img src={Boldness} alt="jobs" />
                    <Typography variant="h6" className={classes.cardTitle}>
                      {contents.company.items[0].title}
                    </Typography>
                    <Typography variant="body1">
                      {contents.company.items[0].content}
                    </Typography>
                  </div>
                  <div className={clsx(classes.cardB)}>
                    <img src={Impact} alt="jobs" />
                    <Typography variant="h6" className={classes.cardTitle}>
                      {contents.company.items[1].title}
                    </Typography>
                    <Typography variant="body1">
                      {contents.company.items[1].content}
                    </Typography>
                  </div>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <div className={clsx(classes.cardB, classes.mT, classes.mB)}>
                    <img src={Rigor} alt="jobs" />
                    <Typography variant="h6" className={classes.cardTitle}>
                      {contents.company.items[2].title}
                    </Typography>
                    <Typography variant="body1">
                      {contents.company.items[2].content}
                    </Typography>
                  </div>
                  <div className={clsx(classes.cardB)}>
                    <img src={Creativity} alt="jobs" />
                    <Typography variant="h6" className={classes.cardTitle}>
                      {contents.company.items[3].title}
                    </Typography>
                    <Typography variant="body1">
                      {contents.company.items[3].content}
                    </Typography>
                  </div>
                </Grid>
                {!matches && (
                  <Grid item lg={12}>
                    <Button variant="contained" fullWidth color="primary">
                      {contents.company.btnLabel}
                    </Button>
                  </Grid>
                )}
              </Grid>
              <TitreTextCta
                title={formatTitle(contents.company.title)}
                text={contents.company.content}
                buttonLabel={contents.company.btnLabel}
                ctaUrl={`/${langKey}/contact?object=jobs`}
              />
            </Grid>
          </Grid>

          <Grid
            item
            className={clsx({
              [classes.row1]: matches,
              [classes.paddingRegularMobile]: !matches,
            })}
          >
            <Grid
              container
              direction={matches ? "row" : "column-reverse"}
              justify="space-between"
              alignItems="center"
              spacing={1}
            >
              {/** TODO gérer multidisciplinary en mobile**/}
              <TitreTextCta
                title={
                  matches
                    ? formatTitle(contents.emersive.title)
                    : formatTitle("Our !!team")
                }
                text={contents.emersive.content}
                buttonLabel={contents.emersive.btnLabel}
                ctaUrl={`/${langKey}/skills`}
              />
              <Grid
                item
                xs={12}
                lg={6}
                className={clsx({
                  [classes.centered]: !matches,
                  [classes.mT]: !matches,
                })}
              >
                <img src={about_illustration} alt="jobs" width="100%" />
              </Grid>
            </Grid>
          </Grid>
          <Video
            srcMp4="https://site.uat.cyclope.ai/static/decouvrez_cyclope2.mp4"
            srcWebm="https://site.uat.cyclope.ai/static/decouvrez_cyclope2.webm"
            placeholderVideo={VideoPlaceholder}
            withBg
          />
          <Grid
            item
            className={clsx({
              [classes.row1]: matches,
              [classes.paddingRegularMobile]: !matches,
            })}
          >
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Typography
                  variant="h1"
                  className={clsx({
                    [classes.centered]: true,
                    [classes.titleMobile]: !matches,
                  })}
                >
                  {formatTitle(contents.team.title)}
                </Typography>
              </Grid>
              <Grid item container direction="column" spacing={matches ? 8 : 0}>
                <Grid item container direction="row" spacing={2}>
                  <Grid item lg={6} xs={12}>
                    <div
                      className={clsx(classes.cardC, {
                        [classes.cardCShadow]: cardIdx === 0,
                      })}
                      onMouseEnter={() => handleMouseEnter(0)}
                    >
                      <img src={Ds} alt="jobs" />
                      <Typography variant="h6" className={classes.cardTitle}>
                        {contents.team.items[0].title}
                      </Typography>
                      <Typography variant="body1" color="textPrimary">
                        {contents.team.items[0].content}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <div
                      className={clsx(classes.cardC, {
                        [classes.cardCShadow]: cardIdx === 1,
                      })}
                      onMouseEnter={() => handleMouseEnter(1)}
                    >
                      <img src={Laptop} alt="jobs" />
                      <Typography variant="h6" className={classes.cardTitle}>
                        {contents.team.items[1].title}
                      </Typography>
                      <Typography variant="body1" color="textPrimary">
                        {contents.team.items[1].content}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid item container direction="row" spacing={2}>
                  <Grid item lg={6} xs={12}>
                    <div
                      className={clsx(classes.cardC, {
                        [classes.cardCShadow]: cardIdx === 2,
                      })}
                      onMouseEnter={() => handleMouseEnter(2)}
                    >
                      <img src={Server} alt="jobs" />
                      <Typography variant="h6" className={classes.cardTitle}>
                        {contents.team.items[2].title}
                      </Typography>
                      <Typography variant="body1" color="textPrimary">
                        {contents.team.items[2].content}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <div
                      className={clsx(classes.cardC, {
                        [classes.cardCShadow]: cardIdx === 3,
                      })}
                      onMouseEnter={() => handleMouseEnter(3)}
                    >
                      <img src={Briefcase} alt="jobs" />
                      <Typography variant="h6" className={classes.cardTitle}>
                        {contents.team.items[3].title}
                      </Typography>
                      <Typography variant="body1" color="textPrimary">
                        {contents.team.items[3].content}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            className={clsx({
              [classes.row1]: matches,
              [classes.paddingRegularMobile]: !matches,
            })}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Carousel images={[Slideshow1, Slideshow2]} />
            </Grid>
            <Grid item>
              <img
                src={LeonardTeam}
                alt="Cyclope.ai team at leonard"
                width="100%"
                className={classes.imgRnd}
              />
            </Grid>
          </Grid>
          <Grid
            item
            className={clsx({
              [classes.lastRow]: matches,
              [classes.lastRowMobile]: !matches,
              [classes.centered]: true,
            })}
            container
            alignItems="center"
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography
                variant="h3"
                className={clsx(classes.leonardTitle, classes.noMargin)}
              >
                Léonard <span className={classes.enLight}>: </span>Paris
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.noMargin}
              >
                6 place du Colonnel Bourgoin,
                <br />
                75012, Paris, France
              </Typography>
            </Grid>
            <Grid item>
              <Grid item container direction="row">
                <Grid item>
                  <IconButton
                    aria-label="twitter account"
                    className={classes.mediumPadded}
                    href="https://www.linkedin.com/company/cyclope-ai"
                    target="__blank"
                  >
                    <LinkedInIcon className={classes.linkedin} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default JobsEn;

export const query = graphql`
  query JobsQueryEn {
    allCareersJson {
      nodes {
        careers {
          en {
            company {
              btnLabel
              content
              items {
                content
                title
              }
              title
            }
            jobs {
              description
              place
              title
              type
            }
            pioneering {
              btnLabel
              content
              title
            }
            emersive {
              title
              content
              btnLabel
            }
            team {
              title
              items {
                content
                title
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        author {
          twitter
        }
        siteTitle
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
